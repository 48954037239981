<template lang="html">
  <section>
    <div
      v-if="modal"
      class="modal fade in"
      ref="dataEditor"
      style="display:none;"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
            <h4 class="modal-title">{{ title }}</h4>
          </div>
          <div class="modal-body">
            <EquipmentDataEditorForm
              v-bind:controls="controls"
              v-model="formData"
            >
            </EquipmentDataEditorForm>
            <!-- div>{{formData}}</div -->
          </div>
          <div class="modal-footer text-right">
            <button
              class="btn btn-primary pull-right"
              v-on:click="save"
              v-bind:disabled="!canSave"
            >
              <i class="fa fa-check"></i> {{ $t("save") }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <div v-else ref="dataEditor">
      <EquipmentDataEditorForm v-bind:controls="controls" v-model="formData">
      </EquipmentDataEditorForm>
      <div class="row">
        <div class="col-md-2 col-md-offset-10">
          <button
            class="btn btn-primary pull-right"
            v-on:click="save"
            v-bind:disabled="!canSave"
            data-testid="save"
          >
            <i class="fa fa-check"></i> {{ $t("save") }}
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import EquipmentDataEditorForm from "@/components/equipment-data-editor-form";
import MixinAlert from "@/project/mixin-alert.js";

export default {
  name: "EquipmentDataEditorPanel",
  components: {
    EquipmentDataEditorForm
  },
  mixins: [MixinAlert],
  props: {
    modal: {
      type: Boolean,
      required: false,
      default: false
    },
    selected: {
      type: Array,
      required: true
    },
    equipment: {
      type: Object,
      required: true
    },
    fields: {
      type: Array,
      required: false,
      default: () => []
    },
    widgetOptions: {
      type: Object,
      required: false,
      default: function () {
        return {
          auth_execution: false,
          auth_verification: false,
          comment_required: false
        };
      }
    },
    panel: {
      type: Object,
      required: false
    }
  },
  data() {
    return {
      options: {
        auth_execution: false,
        auth_verification: false,
        comment_required: false
      },
      formData: null,
      alert: null
    };
  },
  computed: {
    equipmentDataList() {
      return this.$store.getters["dashboard/dataList"] || [];
    },
    title() {
      var self = this;
      var ret = self.equipment.name || self.$t("data_editor");
      //console.log(ret);
      return ret;
    },
    canSave() {
      return this.formData != null && this.saveStatus != "busy";
    },
    filtered() {
      var self = this;
      var equipmentDataList = self.equipmentDataList;
      var lst = equipmentDataList.filter(function (item) {
        return self.selected.indexOf(item.id) >= 0;
      });
      return lst;
    },
    fieldsAsObject() {
      var self = this;
      var ret = null;
      var fields = self.fields;
      if (fields) {
        var lst = fields.map(function (i) {
          return [i.data_id, i];
        });
        if ("fromEntries" in Object) {
          ret = Object.fromEntries(lst);
        } else {
          ret = {};
          for (var i in lst) {
            ret[lst[i][0]] = lst[i][1];
          }
        }
      }
      return ret;
    },
    controls() {
      var n = this.filtered;
      if (n && n.length) {
        var fields = this.fieldsAsObject;
        return n.map((item) => {
          var field = (fields && item.id in fields && fields[item.id]) || null;
          return {
            equipment_id: item.clp_id,
            data_id: item.id,
            value: item?.current_value ? item.current_value.value : null,
            unit: item.unity_label || "",
            type: item?.type,
            dataType: item.memory_type.name || "",
            readOnly: item.read_only,
            name: (field && field.label) || item.name,
            format: (field && field.format) || "",
            decimal: (field && field.decimal) || "",
            required: (field && field.required) || null,
            default: field && "default" in field ? field.default : "",
            min: field && "min" in field ? field.min : null,
            max: field && "max" in field ? field.max : null,
            data: item,
            maxSize: this.$root.$formatter.maxInputLength(item)
          };
        });
      }
      return [];
    },
    saveStatus() {
      return this.$store.getters["command/saveStatus"];
    }
  },
  watch: {
    saveStatus(nVal, oVal) {
      var self = this;
      if (oVal == "busy") {
        if (self.alert) {
          self.$emit("refresh");
          if (self.modal) {
            $(self.$refs.dataEditor).modal("hide");
          } else {
            self.$emit("hide");
            self.showAlert();
          }
        }
      }
    }
  },
  methods: {
    save() {
      this.$emit("save", this.formData);
      this.$emit("postSave");
    },
    resetValue(item) {
      var self = this;
      var lst = self.fields.filter((i) => {
        return i.data_id == item.data_id && "default" in i;
      });
      if (lst && lst.length) {
        item.value = lst[0].default;
      } else {
        item.value = 0;
      }
    }
  },
  mounted() {
    var self = this;
    if (self.modal) {
      $(self.$refs.dataEditor)
        .on("hide.bs.modal", function () {
          self.showAlert(function () {
            self.$emit("toggle");
          });
        })
        .on("shown.bs.modal", function () {
          $(self.$refs.dataEditor).find("input:first").focus();
        })
        .modal("show");
    } else {
    }

    var widgetOptions =
      (self.widgetOptions &&
        "options" in self.widgetOptions &&
        self.widgetOptions.options) ||
      null;
    if (widgetOptions) {
      self.options.auth_execution =
        ("auth_execution" in widgetOptions && widgetOptions.auth_execution) ||
        false;
      self.options.auth_verification =
        ("auth_verification" in widgetOptions &&
          widgetOptions.auth_verification) ||
        false;
      self.options.comment_required =
        ("comment_required" in widgetOptions &&
          widgetOptions.comment_required) ||
        true;
    }
    //self.$nextTick(function(){self.isReady=true;});
  }
};
</script>

<style lang="css" scoped>
.dataEditor {
  position: relative;
}
.modal-title {
  line-height: 1 !important;
}
.modal-footer {
  padding: 5px 15px !important;
}
.modal-body {
  max-height: 400px;
  overflow-y: auto;
}
.saveform-toolbar {
  background-color: #fdfdfd; /*#ecf0f5;*/
  padding: 15px 0 0 0 !important;
  margin-top: -10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #f4f4f4;
  overflow: hidden;
}
</style>
